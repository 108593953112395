import React, { useState } from "react";
import Logo from "./Logo";
import Button from "./Button";
import InsideInput from "./InsideInput";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { logo } from "../constants";
import { Link } from "react-router-dom";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import Spinner from "./Spinner";
import useForm from "../hooks/useForm";

interface EmailFormFields {
  EMAIL: string;
}

export default function Footer() {
  const [error, setError] = useState<string>("");
  const { forgotValues, handleInputChange, errors, validateForgotPassword } =
    useForm(true);

  const currentYear = new Date().getFullYear();
  const MAILCHIMP_URL = process.env.REACT_APP_PUBLIC_MAILCHIMP_URL || "";
  const handleSubmit = async (
    e: React.FormEvent,
    subscribe: (data: EmailFormFields) => void
  ) => {
    setError("");
    e.preventDefault();
    if (validateForgotPassword()) {
      try {
        subscribe({ EMAIL: forgotValues.email });
      } catch (error) {
        setError("An error occurred. Please try again later.");
      }
    }
  };

  const renderNewsLetterForm = () => {
    return (
      <MailchimpSubscribe
        url={MAILCHIMP_URL}
        render={({ subscribe, status, message }) => (
          <form
            onSubmit={(e) => handleSubmit(e, subscribe)}
            className="flex flex-col space-y-6 lg:space-y-3.5"
          >
            <p className="text-[14px] font-bold md:text-start md:text-[15.2px] font-DmSan">
              Stay up to date
            </p>
            <p className="text-[13.5px] md:text-[15.2px] font-DmSan">
              Be the first to know about our newest apartments
            </p>
            <InsideInput
              placeholder="Email Address"
              value={forgotValues.email}
              name="email"
              onChange={handleInputChange}
              styles="rounded-md"
              inputStyle="bg-[#F2F0F2] px-4 py-3"
              error={errors?.email}
            />
            <div className="">
              <Button
                small
                text="Subscribe"
                type="submit"
                icon={
                  status === "sending" && (
                    <Spinner styles="h-4.5 w-4.5 border-2" />
                  )
                }
                disabled={status === "sending"} // Disable button during submission
                buttonStyle="w-[45%] md:text-[15.2px] font-DmSan py-3 text-white bg-gradient-to-r rounded-3xl from-linear_from_color to-linear_to_color"
              />

              {status === "error" && (
                <p className="text-red-200 font-bold pt-2">{error}</p>
              )}
              {status === "success" && (
                <p className="text-green-200 font-bold pt-2">{message}</p>
              )}
            </div>
          </form>
        )}
      />
    );
  };

  return (
    <div className="bg-grey py-20">
      <div className="max-w-[1460px] mx-auto px-10  sm:px-20 lg:px-30">
        <div className="flex flex-col space-y-20 md:space-y-0 lg:flex-row  justify-between">
          <div className="flex flex-col space-y-6 lg:space-y-3.5">
            <Logo logo={logo} styles="h-[61px] w-[138px]" />

            <p className="text-[14px] md:text-[15.2px] font-DmSan">
              Contact number: +256 200932870
            </p>

            <p className="font-bold text-[12px]">©{currentYear} rentify</p>
          </div>
          <div className="flex space-x-11">
            <div className="flex flex-col space-y-6 lg:space-y-3.5">
              <div className="text-[14px] md:text-[15.2px] font-DmSan font-bold md:text-start">
                Company
              </div>
              <Link
                to="/"
                className="transition duration-[800ms] hover:underline hover:underline-offset-8 hover:text-linear_to_color"
              >
                <p className="text-[13.5px] md:text-[15.2px] font-DmSan">
                  Home
                </p>
              </Link>
              <Link
                to="/about"
                className="transition duration-[800ms] hover:underline hover:underline-offset-8 hover:text-linear_to_color"
              >
                <p className="text-[13.5px] md:text-[15.2px] font-DmSan">
                  About us
                </p>
              </Link>
              <Link
                to="/contact"
                className="transition duration-[800ms] hover:underline hover:underline-offset-8 hover:text-linear_to_color"
              >
                <p className="text-[13.5px] md:text-[15.2px] font-DmSan">
                  Contact us
                </p>
              </Link>
            </div>
            <div className="flex flex-col space-y-3.5">
              <div className="text-[14px] md:text-[15.2px] font-DmSan font-bold md:text-start">
                Privacy
              </div>
              <Link
                to="/legal-terms"
                className="transition duration-[800ms] hover:underline hover:underline-offset-8 hover:text-linear_to_color"
              >
                <p className="text-[13.5px] md:text-[15.2px] font-DmSan">
                  Terms of Service
                </p>
              </Link>
              <Link
                to="/legal-terms2"
                className="transition duration-[800ms] hover:underline hover:underline-offset-8 hover:text-linear_to_color"
              >
                <p className="text-[13.5px] md:text-[15.2px] font-DmSan">
                  Privacy Policy
                </p>
              </Link>
            </div>
          </div>
          {renderNewsLetterForm()}
        </div>
      </div>
    </div>
  );
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthState } from "./types";
import {
  getLocalStorageToken,
  removeLocalStorageToken,
} from "../../utils/localStorageToken";
import { resetPropertyState } from "../property/propertySlice";

const initialState: AuthState = {
  register_message: null,
  login_message: null,
  registration_error: null,
  login_error: null,
  confirmation_error: null,
  confirmation_message: null,
  token: getLocalStorageToken(),
  userInfo: null,
  rememberMe: false,
  forgot_error: null,
  forgot_message: null,
  reset_error: null,
  reset_message: null,
  isAuthenticated: false,
  user_loading: false,
  error: null,
  verification_error: null,
  verification_message: null,
  profile_error: null,
  profile_message: null,
  verify_error: null,
  verify_message: null,
  message: null,
  post_message: null,
  post_error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      removeLocalStorageToken();
      resetPropertyState();
      state.token = null;
      state.userInfo = null;
      state.register_message = null;
      state.login_message = null;
      state.registration_error = null;
      state.login_error = null;
      state.isAuthenticated = false;
    },
    setUserData: (state, { payload }) => {
      if (payload !== null) {
        state.userInfo = payload;
      }
    },
    createRegisterMessage: (state, { payload }: PayloadAction<string>) => {
      state.register_message = payload;
    },
    createProfileMessage: (state, { payload }: PayloadAction<string>) => {
      state.profile_message = payload;
    },
    createForgotMessage: (state, { payload }: PayloadAction<string>) => {
      state.forgot_message = payload;
    },
    createConfirmationMessage: (state, { payload }: PayloadAction<string>) => {
      state.confirmation_message = payload;
    },
    createResetMessage: (state, { payload }: PayloadAction<string>) => {
      state.reset_message = payload;
    },
    createLoginMessage: (state, { payload }: PayloadAction<string>) => {
      state.login_message = payload;
    },
    createRegisterError: (state, { payload }: PayloadAction<string>) => {
      state.registration_error = payload;
    },
    createResetError: (state, { payload }: PayloadAction<string>) => {
      state.reset_error = payload;
    },
    createLoginError: (state, { payload }: PayloadAction<string>) => {
      console.log(payload);
      state.login_error = payload;
    },
    createConfirmationError: (state, { payload }: PayloadAction<string>) => {
      state.confirmation_error = payload;
    },
    createForgotError: (state, { payload }: PayloadAction<string>) => {
      state.forgot_error = payload;
    },

    createProfileError: (state, { payload }: PayloadAction<string>) => {
      state.profile_error = payload;
    },

    createVerificationMessage: (state, { payload }: PayloadAction<string>) => {
      state.verification_message = payload;
    },
    createVerificationError: (state, { payload }: PayloadAction<string>) => {
      state.verification_error = payload;
    },

    createVerifyMessage: (state, { payload }: PayloadAction<string>) => {
      state.verify_message = payload;
    },
    createVerifyError: (state, { payload }: PayloadAction<string>) => {
      state.verify_error = payload;
    },
    createMessage: (state, { payload }: PayloadAction<string>) => {
      state.message = payload;
    },
    clearMessage: (state) => {
      state.login_message = null;
      state.register_message = null;
      state.confirmation_message = null;
      state.forgot_message = null;
      state.reset_message = null;
      state.verification_message = null;
      state.profile_message = null;
      state.verify_message = null;
      state.message = null;
      state.post_message = null;
    },
    clearError: (state) => {
      state.login_error = null;
      state.registration_error = null;
      state.confirmation_error = null;
      state.forgot_error = null;
      state.reset_error = null;
      state.verification_error = null;
      state.profile_error = null;
      state.verify_error = null;
      state.post_error = null;
    },
    setRememberMe: (state, { payload }) => {
      state.rememberMe = payload;
    },
    setIsAuthenticated: (state, { payload }) => {
      state.isAuthenticated = payload;
    },
  },
});

export const {
  createRegisterMessage,
  createLoginMessage,
  clearMessage,
  createConfirmationError,
  createConfirmationMessage,
  createRegisterError,
  createLoginError,
  createForgotMessage,
  createForgotError,
  createResetMessage,
  createResetError,
  clearError,
  logout,
  setRememberMe,
  setUserData,
  setIsAuthenticated,
  createVerificationError,
  createVerificationMessage,
  createProfileError,
  createProfileMessage,
  createVerifyError,
  createVerifyMessage,
  createMessage,
} = authSlice.actions;

export default authSlice.reducer;

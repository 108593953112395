import React, { useEffect, useRef } from "react";
import Button from "./Button";
import { ChevronDownIcon, ClockIcon, EnvelopeIcon, PowerIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { useDispatch } from "react-redux";
import { logout } from "../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import useApp from "../hooks/useApp";

const DropdownUser = () => {
  const trigger = useRef(null);
  const dropdown = useRef(null);
  const [menuDown, setMenuDown] = React.useState(false);
  const { userInfo } = useApp();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent): void => {
      if (!dropdown.current) return;
      if (
        !menuDown ||
        (dropdown.current as HTMLElement).contains(target as Node) ||
        (trigger.current && (trigger.current as HTMLElement).contains(target as Node))
      )
        return;
      setMenuDown(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  }, [menuDown]);

  // close if the esc key is pressed
  useEffect(() => {
    console.log("uer info", userInfo);
    const keyHandler = ({ keyCode }: KeyboardEvent): void => {
      if (!menuDown || keyCode !== 27) return;
      setMenuDown(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  }, [menuDown]);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  return (
    <div>
      <div
        ref={trigger}
        onClick={() => setMenuDown(!menuDown)}
        className="flex items-center space-x-2 cursor-pointer transition ease-out duration-100"
      >
        {userInfo?.nationalPhotoId !== null ? (
          <div className={`h-8 w-8 rounded-full overflow-hidden bg-cover`}>
            {/* @ts-ignore */}
            <img src={userInfo?.nationalPhotoId} alt="" className="min-w-[100%] min-h-[100%]" />
          </div>
        ) : (
          <UserCircleIcon className="h-8 w-8 text-black" />
        )}
        <ChevronDownIcon className="h-4 w-4" />
      </div>

      <div
        ref={dropdown}
        onFocus={() => setMenuDown(true)}
        onBlur={() => setMenuDown(false)}
        className={`absolute right-[18%] mt-2 flex w-62.5 cursor-pointer flex-col rounded-xl p-6 bg-white shadow-xl ${
          menuDown === true ? "block" : "hidden"
        }`}
      >
        <div className="flex flex-col space-y-1 py-3">
          <div className="flex items-center  space-x-3 py-2 text-sm font-medium duration-300 ease-in-out lg:text-base">
            {/* <EnvelopeIcon className="w-5 h-5" /> */}
            <div>
              <h3 className="font-bold text-sm">{userInfo?.email}</h3>
            </div>
          </div>
          <div className="flex items-center  space-x-3 py-2 text-sm font-medium duration-300 ease-in-out lg:text-base">
            <EnvelopeIcon className="w-5 h-5" />
            <div>
              <h3 className="font-bold text-sm">Messages</h3>
            </div>
          </div>
          <div
            onClick={() => navigate("/home")}
            className="flex items-center  space-x-3 py-2 text-sm font-medium duration-300 ease-in-out lg:text-base"
          >
            <ClockIcon className="w-5 h-5" />
            <div>
              <h3 className="font-bold text-sm">Recent Viewed</h3>
            </div>
          </div>
        </div>

        <Button
          onClick={handleLogout}
          leftIcon={<PowerIcon className="w-5 h-5" />}
          text="Log out"
          buttonStyle="bg-[#FFD5D5] flex items-center py-2.5 px-5 text-sm font-medium duration-300 rounded-md ease-in-out lg:text-base"
        />
      </div>
    </div>
  );
};

export default DropdownUser;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { rentifyApi } from "../../api";

export const getCities = createAsyncThunk(
  "cities/get",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await rentifyApi.get("/cities");

      return data.location;
    } catch (error: any) {
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error.message);
      } else {
        return rejectWithValue(error.response.data.error.message);
      }
    }
  }
);

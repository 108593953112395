import Modal from "./Modal";
import Button from "../Button";
import InsideInput from "../InsideInput";
import { useEffect, useRef, useState } from "react";

type Props = {
  isOpen: boolean;
  Close: () => void;
  min?: any;
  max?: any;
  step?: any;
  priceCap?: any;
  setMinValue: (e: any) => void;
  setMaxValue: (e: any) => void;
};

export default function PriceRangeModal({
  isOpen,
  Close,
  min,
  max,
  step,
  priceCap,
  setMaxValue,
  setMinValue,
}: Props) {
  const progressRef = useRef<HTMLDivElement>(null);
  const [terminalMinPrice, setTerminalMinPrice] = useState<number | any>(0);
  const [terminalMaxPrice, setTerminalMaxPrice] = useState<number | any>(
    200000
  );

  const handleMin = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      priceCap !== undefined &&
      terminalMaxPrice !== undefined &&
      terminalMinPrice !== undefined &&
      max !== undefined &&
      terminalMaxPrice - terminalMinPrice >= priceCap &&
      terminalMaxPrice < max
    ) {
      const newValue = parseInt(e.target.value);
      if (newValue > terminalMaxPrice) {
        // Do nothing
      } else {
        setTerminalMinPrice(newValue);
      }
    } else {
      if (
        terminalMinPrice !== undefined &&
        parseInt(e.target.value) < terminalMinPrice
      ) {
        setTerminalMinPrice(parseInt(e.target.value));
      }
    }
  };

  const handleMax = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      priceCap !== undefined &&
      terminalMaxPrice !== undefined &&
      terminalMinPrice !== undefined &&
      max !== undefined &&
      terminalMaxPrice - terminalMinPrice >= priceCap &&
      terminalMaxPrice < max
    ) {
      const newValue = parseInt(e.target.value);
      if (newValue < terminalMinPrice) {
        // Do nothing
      } else {
        setTerminalMaxPrice(newValue);
      }
    } else {
      if (
        terminalMaxPrice !== undefined &&
        parseInt(e.target.value) > terminalMaxPrice
      ) {
        setTerminalMaxPrice(parseInt(e.target.value));
      }
    }
  };

  useEffect(() => {
    if (
      progressRef?.current &&
      max !== undefined &&
      step !== undefined &&
      terminalMinPrice !== undefined &&
      terminalMaxPrice !== undefined
    ) {
      const leftPosition = (terminalMinPrice / max) * step + "%";
      const rightPosition = step - (terminalMaxPrice / max) * step + "%";
      progressRef.current.style.left = leftPosition;
      progressRef.current.style.right = rightPosition;
    }
  }, [terminalMinPrice, terminalMaxPrice, step, max]);

  const bodyContent = (
    <div className="flex flex-col space-y-6 px-5 md:px-20 lg:px-10 overflow-hidden">
      <h5 className="font-bold text-[20px] md:text-[24px] font-DmSan">
        Price Range
      </h5>
      <div>
        <div className="slider relative h-1.5 rounded-md bg-gray-300">
          <div
            className="progress absolute h-1.5 bg-tertiary_color"
            ref={progressRef}
          ></div>
        </div>
        <div className="range-input relative">
          <input
            type="range"
            className="range-min absolute w-full h-1.5 bg-transparent -top-1 appearance-none pointer-events-none"
            min={min || 0}
            step={step}
            max={max}
            value={terminalMinPrice}
            onChange={handleMin}
          />
          <input
            type="range"
            className="range-max absolute w-full h-1.5 bg-transparent -top-1 appearance-none pointer-events-none"
            min={min || 0}
            step={step}
            max={max}
            value={terminalMaxPrice}
            onChange={handleMax}
          />
        </div>
      </div>
      <div className="flex items-center justify-center space-x-10">
        <div>
          <InsideInput
            value={terminalMinPrice}
            placeholder="UGX0"
            onChange={(e) => setTerminalMinPrice(e.target.value)}
            styles="rounded-3xl"
            inputStyle="py-4 border border-stroke rounded-3xl"
            isInputStyle
          />
        </div>
        <div>-</div>
        <div>
          <InsideInput
            placeholder="UGX 500"
            value={terminalMaxPrice}
            onChange={(e) => setTerminalMaxPrice(e.target.value)}
            styles="rounded-3xl"
            inputStyle="py-4 border border-stroke rounded-3xl"
            isInputStyle
          />
        </div>
      </div>
    </div>
  );

  const footerContent = (
    <div className="flex items-center justify-center">
      <Button
        small
        text="Apply"
        onClick={() => {
          setMinValue(terminalMinPrice);
          setMaxValue(terminalMaxPrice);
          Close();
        }}
        buttonStyle="w-[30%] py-4 text-white font-bold bg-gradient-to-r rounded-3xl from-linear_from_color to-linear_to_color"
      />
    </div>
  );

  return (
    <Modal
      styles="p-6"
      isOpen={isOpen}
      actionLabel="Continue"
      onClose={Close}
      body={bodyContent}
      footer={footerContent}
      modalStyles="w-full md:w-3/6 2xl:w-2/6 my-6 mx-auto h-6/6 lg:h-auto md:h-auto"
    />
  );
}

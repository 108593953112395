import { useCallback, useEffect, useRef, useState } from "react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
// import { useDispatch } from "react-redux";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  body?: React.ReactElement;
  footer?: React.ReactElement;
  actionLabel?: string;
  disabled?: boolean;
  leftIcon?: boolean;
  styles?: string;
  modalStyles?: string;
  height?: string;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  styles,
  onClose,
  footer,
  body,
  disabled,
  leftIcon,
  modalStyles,
  height,
}) => {
  const [showModal, setShowModal] = useState<boolean>(isOpen);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setShowModal(isOpen);
  }, [isOpen]);

  const handleClose = useCallback(() => {
    if (disabled) {
      return;
    }
    setShowModal(false);
    setTimeout(() => {
      onClose();
    }, 300);
  }, [disabled, onClose]);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    if (showModal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showModal, handleClickOutside]);

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-99 outline-none focus:outline-none bg-black bg-opacity-10 px-4 md:px-0">
        <div ref={modalRef} className={`relative ${modalStyles}`}>
          <div
            className={`translate duration-[800ms] ${
              showModal
                ? "translate-y-0 opacity-100"
                : "translate-y-full opacity-0"
            }`}
          >
            {/* content */}
            <div
              className={`translate ${height} overflow-x-hidden  md:overflow-hidden  rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none`}
            >
              {/* header */}
              <div
                className={`flex items-center justify-end px-6 py-2 rounded-t  relative ${
                  leftIcon && "justify-between"
                }`}
              >
                {leftIcon && (
                  <div className="flex items-center space-x-6">
                    <div className="p-1 rounded-full hover:bg-background_color_second hover:opacity-70 transition">
                      <ChevronLeftIcon className="w-4 h-4" />
                    </div>
                    <div className="p-1 rounded-full hover:bg-background_color_second hover:opacity-70 transition">
                      <ChevronRightIcon className="w-4 h-4" />
                    </div>
                  </div>
                )}
                <button
                  onClick={handleClose}
                  className="p-1 rounded-full hover:bg-background_color_second hover:opacity-70 transition"
                >
                  <XMarkIcon className="w-6 h-6 text-tertiary_color" />
                </button>
              </div>
              {/* body */}
              <div className={`relative ${styles} flex-auto`}>{body}</div>
              {/* footer */}
              <div className="flex flex-col gap-2 p-6">{footer}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import appReducer from "./features/app/appSlice";
import authReducer from "./features/auth/authSlice";
import propertyReducer from "./features/property/propertySlice";
import bookingReducer from "./features/book/bookSlice";
export type AppDispatch = typeof store.dispatch;

const persistConfig = {
  key: "rentifyDev",
  storage,
  // whitelist: ["auth", "app"],
};

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  property: propertyReducer,
  booking: bookingReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

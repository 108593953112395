import React from "react";

type Props = {
  label?: string;
  name?: string;
  type: any;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean | undefined;
  left?: boolean;
  right?: boolean;
  value?: string;
  disabled?: boolean;
};

export default function CheckBox({
  label,
  name,
  type,
  value,
  disabled,
  onChange,
  checked,
  left,
  right,
}: Props) {
  return (
    <div className="flex items-center">
      {left && (
        <label className="ml-2 text-[14px] md:text-[15px] text-black  text-center">
          {label}
        </label>
      )}
      <div className="flex items-center">
        <input
          type={type}
          value={value}
          onChange={onChange}
          name={name}
          checked={checked}
          className="w-4 h-4 border border-[#D9D9D9] rounded  focus:ring-3 focus:ring-blue-300 cursor-pointer"
          disabled={disabled}
        />
      </div>
      {right && (
        <label className="ml-2 text-[14px] md:text-[15px] text-black">
          {label}
        </label>
      )}
    </div>
  );
}

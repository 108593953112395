import React, { useState } from "react";
import CheckBox from "./CheckBox";
import useProperty from "../hooks/useProperty";
import { addSelectedAmenities } from "../features/property/propertySlice";

type AmenitieComponentProps = {
  amenities: string[];
};

export default function AmenitieComponent({
  amenities,
}: AmenitieComponentProps) {
  const leftItems = amenities.slice(0, Math.ceil(amenities.length / 2));
  const rightItems = amenities.slice(Math.ceil(amenities.length / 2));
  const { selectedAmenities, dispatch } = useProperty();

  const [leftFlexGrow, setLeftFlexGrow] = useState(1);
  const [rightFlexGrow, setRightFlexGrow] = useState(1);

  React.useEffect(() => {
    const leftLength = leftItems.length;
    const rightLength = rightItems.length;
    setLeftFlexGrow(leftLength > rightLength ? 2 : 1);
    setRightFlexGrow(leftLength < rightLength ? 2 : 1);
  }, [amenities, leftItems.length, rightItems.length]);

  return (
    <div className="flex">
      <div
        className="amenities-left flex flex-col space-y-5"
        style={{ flexGrow: leftFlexGrow }}
      >
        {leftItems.map((item, i) => {
          return (
            <CheckBox
              key={i}
              right
              label={item}
              type="checkbox"
              checked={selectedAmenities?.includes(item)}
              onChange={() => dispatch(addSelectedAmenities(item))}
            />
          );
        })}
      </div>
      <div
        className="amenities-right flex flex-col space-y-5"
        style={{ flexGrow: rightFlexGrow }}
      >
        {rightItems.map((item, i) => {
          return (
            <CheckBox
              key={i}
              right
              label={item}
              type="checkbox"
              checked={selectedAmenities?.includes(item)}
              onChange={() => dispatch(addSelectedAmenities(item))}
            />
          );
        })}
      </div>
    </div>
  );
}

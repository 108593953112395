import React from "react";

type RadioProps = {
  type?: string;
  name?: string;
  disabled?: boolean;
  label?: string;
  value?: string;
  checked?: boolean | undefined;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function RadioButton({
  type,
  name,
  value,
  label,
  disabled,
  checked,
  onChange,
}: RadioProps) {
  return (
    <div className="flex items-center">
      <input
        disabled={disabled}
        type={type || "radio"}
        value={value}
        name={name}
        checked={checked}
        onChange={onChange}
        className="w-4 md:w-6 h-4 md:h-6 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
      />
      <label className="ms-2 text-sm md:text-[17px] font-DmSan">{label}</label>
    </div>
  );
}

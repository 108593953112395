import { useDispatch, useSelector } from "react-redux";
import { Location, useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../features/app/types";
import { addImage } from "../features/app/appSlice";
import { RootAuthState } from "../features/auth/types";

function getLocation(location: Location) {
  const searchParams = new URLSearchParams(location.search);
  const value = searchParams.get("id");
  return value;
}

export default function useApp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const value = getLocation(location);

  const {
    currentItem,
    displaySearch,
    dropDown,
    image,
    checked,
    menuDown,
    isOpen,
    currentProperty,
    currentPosition,
    get_districts_loading,
    districts,
  } = useSelector((state: RootState) => state.app);

  const {
    login_error,
    userInfo,
    isAuthenticated,
    registration_error,
    register_message,
    forgot_error,
    forgot_message,
    reset_error,
    reset_message,
  } = useSelector((state: RootAuthState) => state.auth) || {};

  const handleImage = async (e: any) => {
    const { name, files } = e.target;

    const handleImageDispatch = async (actionType: any) => {
      if (files && files[0]) {
        const image = files[0];
        const object = {
          img: URL.createObjectURL(image),
          name: image.name,
          image: image,
        };
        dispatch(actionType(object));
      }
    };

    if (name === "image") {
      await handleImageDispatch(addImage);
    }
  };

  return {
    dispatch,
    currentItem,
    location,
    navigate,
    displaySearch,
    value,
    dropDown,
    menuDown,
    isOpen,
    handleImage,
    image,
    checked,
    currentProperty,
    login_error,
    userInfo,
    isAuthenticated,
    registration_error,
    register_message,
    currentPosition,
    forgot_error,
    forgot_message,
    get_districts_loading,
    districts,
    reset_error,
    reset_message,
  };
}

import { lazy, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { Supensing } from "./components";
import { AuthenticationRoute, PublicRoutes } from "./utils/AuthenticationRoute";
import ReactGA from "react-ga";

const HomePage = lazy(() => import("./pages/app/Home"));
const AboutPage = lazy(() => import("./pages/app/About"));
const ContactPage = lazy(() => import("./pages/app/Contact"));
const LandLordPage = lazy(() => import("./pages/app/LandLord"));
const ListingsPage = lazy(() => import("./pages/app/Listings"));
const SearchPage = lazy(() => import("./pages/app/Search"));
const PropertyDetailsPage = lazy(() => import("./pages/app/propertyDetails"));
const AddPropertyPage = lazy(() => import("./pages/app/AddProperty"));
const EditPropertyPage = lazy(() => import("./pages/app/EditProperty"));
const LegalTermsPage = lazy(() => import("./pages/app/LegalTerms"));
const LegalTermsPage2 = lazy(() => import("./pages/app/LegalTerms2"));
const LoginPage = lazy(() => import("./pages/auth/Login"));
const ForgotPasswordPage = lazy(() => import("./pages/auth/ForgotPassword"));
const ResetPasswordPage = lazy(() => import("./pages/auth/ResetPassword"));
const UserInfoForm = lazy(() => import("./pages/app/UserInfoForm"));

const TRACKING_ID = "G-WFK2WLWJCB";
ReactGA.initialize(TRACKING_ID);

function App() {
  window.addEventListener("error", (event) => {
    event.preventDefault();
  });

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Routes>
      <Route element={<PublicRoutes isPublic={true} />}>
        <Route index element={<Supensing Component={HomePage} />} />
        <Route path="/home" element={<Supensing Component={HomePage} />} />
        <Route path="/about" element={<Supensing Component={AboutPage} />} />
        <Route path="/contact" element={<Supensing Component={ContactPage} />} />
        <Route path="/search" element={<Supensing Component={SearchPage} />} />
        <Route path="/property-details/:id" element={<Supensing Component={PropertyDetailsPage} />} />
        <Route path="/legal-terms" element={<Supensing Component={LegalTermsPage} />} />
        <Route path="/legal-terms2" element={<Supensing Component={LegalTermsPage2} />} />
        <Route path="/registration-form" element={<Supensing Component={UserInfoForm} />} />

        {/*  */}
        <Route path="/register" element={<Supensing Component={LandLordPage} />} />
        <Route path="/login" element={<Supensing Component={LoginPage} />} />
        <Route path="/forgot-password" element={<Supensing Component={ForgotPasswordPage} />} />
        <Route path="/reset-password/:token" element={<Supensing Component={ResetPasswordPage} />} />
      </Route>
      <Route element={<AuthenticationRoute />}>
        <Route path="/listings" element={<Supensing Component={ListingsPage} />} />

        <Route path="/add-property" element={<Supensing Component={AddPropertyPage} />} />
        <Route path="/edit-property/:id" element={<Supensing Component={EditPropertyPage} />} />
      </Route>
    </Routes>
  );
}

export default App;

import React, { useState } from "react";
import InsideInput from "./InsideInput";
import Button from "./Button";
import { Link } from "react-router-dom";
import ConfirmationModal from "./modal/ConfirmationModal";
import useForm from "../hooks/useForm";
import { useMutation } from "@tanstack/react-query";
import {
  clearError,
  clearMessage,
  createRegisterError,
  createRegisterMessage,
  setIsAuthenticated,
  setUserData,
} from "../features/auth/authSlice";
import { AxiosResponse } from "axios";
import useApp from "../hooks/useApp";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import Spinner from "./Spinner";
import FileInput from "./FileInput";
import useImageUpload from "../hooks/useImageUpload";
import { setLocalStorageToken } from "../utils/localStorageToken";
import { rentifyApi } from "../api";

export default function RegisterLandLordForm() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { dispatch, registration_error, register_message, navigate } = useApp();
  const emailValue = window.localStorage.getItem("email");

  const {
    personalPhoto,
    nationalIdPhoto,
    handleFirebaseNationalIdPhoto,
    handleFirebasePersonalPicture,
    loading,
    uploadProfileComplete,
    uploadComplete,
  } = useImageUpload(dispatch);

  const { handleInputChange, registerValues, showPassword, errors, handleShowPassword, validateRegister } = useForm(
    true,
    "register"
  );

  const mutation = useMutation({
    mutationFn: () =>
      rentifyApi.post("/signup", {
        ...registerValues,
        nationalIdPhotoUrl: personalPhoto,
        profilePhotoUrl: "none",
        nationalIDnumber: "none",
      }),
    onSuccess: (res: AxiosResponse) => {
      if (res.data) {
        if (res.status === 200) {
          dispatch(setUserData(res.data.land));
          setLocalStorageToken(res.data.token);
          dispatch(setIsAuthenticated(true));
          dispatch(createRegisterMessage("Your account was created successfully, please login"));
        }
      }
    },
    onError: (error: any) => {
      if (error && error.response) {
        dispatch(createRegisterError(error.response.data?.error));
      }
    },
  });

  if (register_message) {
    setTimeout(() => {
      dispatch(clearMessage());
      setIsOpen(true);
      setTimeout(() => {
        navigate("/listings");
      }, 2000);
    }, 3000);
  }

  if (registration_error) {
    setTimeout(() => {
      dispatch(clearError());
    }, 3000);
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (validateRegister()) {
      if (personalPhoto) {
        mutation.mutate();
      } else {
        dispatch(createRegisterError("Please add your personalPhoto"));
      }
    }
  };

  const handleProfile = (e: React.ChangeEvent<HTMLInputElement>, profile: string) => {
    const { files } = e.target;
    if (registerValues.email) {
      if (files && files[0] && emailValue) {
        const image = files[0];
        if (profile === "personalPic") {
          handleFirebasePersonalPicture(image, "profilePics", emailValue);
        } else {
          handleFirebaseNationalIdPhoto(image, "nationalIdsPics", emailValue);
        }
      }
    } else {
      dispatch(createRegisterError("Please first fill in your email and other fields."));
    }
  };

  return (
    <div className="bg-white rounded-xl px-8 py-5 flex flex-col space-y-6">
      <h6 className="font-semibold text-[20px] sm:text-[26px] md:text-[28px] lg:text-[30px] text-center">
        Register as a field agent.
      </h6>
      <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
        <InsideInput
          placeholder="Name*"
          type="text"
          value={registerValues.name}
          name="name"
          onChange={handleInputChange}
          styles="rounded-md"
          inputStyle="px-4 py-4 border border-stroke rounded-md "
          isInputStyle
          error={errors.name}
        />
        <InsideInput
          placeholder="Email*"
          type="text"
          value={registerValues.email}
          name="email"
          onChange={handleInputChange}
          styles="rounded-md"
          inputStyle="px-4 py-4 border border-stroke rounded-md "
          isInputStyle
          error={errors?.email}
        />
        <InsideInput
          placeholder="Phone Number*"
          type="text"
          value={registerValues.phoneNumber}
          name="phoneNumber"
          onChange={handleInputChange}
          styles="rounded-md"
          inputStyle="px-4 py-4 border border-stroke rounded-md "
          isInputStyle
          error={errors?.phone_number}
        />
        <InsideInput
          placeholder="Password*"
          type={showPassword ? "text" : "password"}
          value={registerValues.password}
          name="password"
          onChange={handleInputChange}
          styles="rounded-md"
          inputStyle="px-4 py-4 border border-stroke rounded-md"
          isInputStyle
          error={errors?.password}
          iconRight={
            <div className="px-2" onClick={handleShowPassword}>
              {showPassword ? <EyeIcon className="w-5 h-5" /> : <EyeSlashIcon className="w-5 h-5" />}
            </div>
          }
        />
        <InsideInput
          placeholder="Confirm password*"
          type={showPassword ? "text" : "password"}
          value={registerValues.confirmPassword}
          name="confirmPassword"
          onChange={handleInputChange}
          styles="rounded-md"
          inputStyle="px-4 py-4 border border-stroke rounded-md"
          isInputStyle
          error={errors?.confirmPassword}
        />
        {/* <div className="w-full flex items-center space-x-4">
          <div>
            <InsideInput
              placeholder="National ID number*"
              value={registerValues.nationalIDnumber}
              name="nationalIDnumber"
              onChange={handleInputChange}
              styles="rounded-md"
              inputStyle="px-4 py-4 border border-stroke rounded-md"
              isInputStyle
              error={errors?.national_id}
            />
          </div>

          <div className="flex-1">
            <FileInput
              label="National ID Photo"
              type="file"
              name="file"
              onChange={(e) => handleProfile(e, "nationalId")}
            />
            {uploadProfileComplete && (
              <p className="text-success_color text-[12px]">National Id uploaded successfully</p>
            )}
          </div>
        </div> */}
        <div className="flex-1">
          <FileInput
            label="Personal Picture"
            type="file"
            name="file"
            onChange={(e) => handleProfile(e, "personalPic")}
          />
          {uploadComplete && <p className="text-success_color text-[12px]">Personal Picture uploaded successfully</p>}
        </div>

        {loading && (
          <div className="flex items-center space-x-3">
            <Spinner styles="h-4 w-4 border-2 text-primary_color" />
            <p className="text-[13px] text-success_color"> Uploading ...</p>
          </div>
        )}

        {registration_error && <p className="text-[13px] text-primary_color">{registration_error}</p>}
        <Button
          small
          type="submit"
          disabled={mutation.isPending}
          text={mutation.isPending ? <Spinner styles="h-4.5 w-4.5 border-2" /> : "Submit for review"}
          buttonStyle="py-4 text-[14px] sm:text-[15px] md:text[16px] lg:text-[16.5px] font-DmSan text-white bg-gradient-to-r rounded-md from-linear_from_color to-linear_to_color"
        />
      </form>
      <div className="flex flex-col space-y-1">
        <p className="text-[14px] sm:text-[15px] md:text[16px] lg:text-[16.5px] font-DmSan leading-6">
          Already a Landlord on Rentify, please{" "}
          <span onClick={() => navigate("/login")} className="text-tertiary_color cursor-pointer">
            login here
          </span>
        </p>
        <p className="text-[14px] sm:text-[15px] md:text[16px] lg:text-[16.5px] font-DmSan leading-6">
          By submitting this form, i am agreeing to the Rentify{" "}
          <Link to="/legal-terms">
            <span className="text-tertiary_color">Rentify Terms</span>
          </Link>{" "}
          and{" "}
          <Link to="/legal-terms2">
            <span className="text-tertiary_color">privacy policy</span>
          </Link>
        </p>
      </div>
      <ConfirmationModal isOpen={isOpen} Close={() => setIsOpen(false)} />
    </div>
  );
}

import React from "react";

export default function CustomStepMobile({
  leftTitle,
  rightTitle,
}: {
  leftTitle?: React.ReactElement;
  rightTitle?: React.ReactElement;
}) {
  return (
    <div className="flex flex-col lg:flex-row relative">
      <div className="flex flex-col flex-1">{leftTitle}</div>

      <div className="flex flex-col flex-1">{rightTitle}</div>
    </div>
  );
}

import React from "react";

type Props = {
  label?: string;
  sublabel?: string;
  icon?: React.ReactElement;
  styles?: string;
  type?: File | any;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  multiple?: boolean;
  name: string;
  accept?: string;
};

export default function FileInputProperty({
  label,
  sublabel,
  icon,
  styles,
  type,
  name,
  accept,
  onChange,
  multiple,
}: Props) {
  return (
    <label className={`${styles}`}>
      <div className="flex flex-col space-y-1 items-center">
        {icon && icon}
        {label && <p className="text-[14px] text-black ">{label}</p>}
        {sublabel && <p className="text-[12px] text-black">{sublabel}</p>}
      </div>
      <input
        className="hidden relative m-0 w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200"
        type={type}
        multiple={multiple}
        onChange={onChange}
        name={name}
        accept={accept}
      />
    </label>
  );
}

import React from "react";
interface InputProps {
  value: any;
  name?: string;
  label?: string;
  type?: string;
  styles?: string;
  disabled?: boolean;
  required?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  isInputStyle?: boolean;
  onFocus?: VoidFunction;
  iconRight?: React.ReactNode;
  inputStyle?: String;
  error?: string | null | boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  min?: number;
}

const InsideInput: React.FC<InputProps> = ({
  value,
  name,
  placeholder,
  type,
  disabled,
  error,
  readOnly,
  label,
  inputStyle,
  styles,
  onChange,
  onFocus,
  onKeyDown,
  required,
  iconRight,
  isInputStyle,
  min,
}) => {
  return (
    <div className={`w-full relative border-stroke ${styles}`}>
      <div className="flex flex-col">
        <div className={`flex items-center ${inputStyle}`}>
          <input
            disabled={disabled}
            placeholder={placeholder}
            type={type}
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onFocus={onFocus}
            required={required}
            readOnly={readOnly}
            name={name}
            min={min}
            className={`w-full text-[16px] outline-none ${
              isInputStyle ? "bg-white" : "bg-[#F2F0F2]"
            } transition disabled:opacity-70 disabled:cursor-not-allowed pl-4 `}
          />
          {iconRight && iconRight}
        </div>
        {error && <p className="text-primary_color text-sm">{error}</p>}
      </div>
    </div>
  );
};

export default InsideInput;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PropertyState, PropertyType } from "./types";
import {
  createProperty,
  getProperties,
  getPropertyListingsBooked,
  getPropertyListingsDraft,
  getPropertyListingsLive,
  getPropertyListingsPending,
  getSearch,
  getSimilarPropertyListings,
  propertyViews,
  updateProperty,
} from "./propertyAction";

const initialState: PropertyState = {
  properties: [],
  searchedProperties: [],
  similar_properties: [],
  booked_properties: [],
  draft_properties: [],
  live_properties: [],
  pending_properties: [],
  recent_properties: [],
  bedrooms: 0,
  livingRoom: 0,
  bathroom: 0,
  Kitchen: 0,
  amenities: [
    "Air conditioning",
    "Bed linen",
    "King size bed",
    "Kitchen/kitchenette",
    "Elevator",
    "Pool (private)",
    "Fireplace",
    "Pool (shared)",
    "Gym nearby",
    "Queen size bed",
    "Gym onsite",
    "Stand alone property",
    "Computer",
    "Iron",
  ],
  property_rules: [],
  propertPhotosUrl: [],
  selectedAmenities: [],
  property_message: null,
  property_error: null,
  selectedProperty: "",
  get_property_loading: false,
  get_search_loading: false,
  get_similar_property_loading: false,
  get_booked_property_loading: false,
  get_live_property_loading: false,
  get_draft_property_loading: false,
  get_pending_property_loading: false,
  update_property_loading: false,
  create_property_loading: false,
  create_approval_property_loading: false,
  delete_property_loading: false,
  currentPropertyStatus: "Picked for you",
  selectedCity: null,
  currentPropertyPage: null,
  totalPropertyPages: null,
  totalPropertySearchPages: null,
  totalPropertyItems: null,
  currentLivePage: null,
  totalLivePages: null,
  totalLiveItems: null,
  currentBookedPage: null,
  totalBookedPages: null,
  totalBookedItems: null,
  currentDraftPage: null,
  totalDraftPages: null,
  totalDraftItems: null,
  maxPrice: 50000,
  minPrice: 200000,
  rooms: 0,
};

const propertySlice = createSlice({
  name: "property",
  initialState,
  reducers: {
    setSelectedCity: (state, { payload }: PayloadAction<string>) => {
      state.selectedCity = payload;
    },

    removeSelectedCity: (state) => {
      state.selectedCity = null;
    },

    setCurrentPropertyStatus: (state, { payload }: PayloadAction<string>) => {
      state.currentPropertyStatus = payload;
    },

    setRecentViewProperty: (state, { payload }: PayloadAction<PropertyType>) => {
      const uniquePropertyIds = new Set(state.recent_properties?.map((property) => property.id) || []);

      if (payload !== null && !uniquePropertyIds.has(payload.id)) {
        state.recent_properties?.push(payload);
        uniquePropertyIds.add(payload.id); // Add the new property ID to the Set
      }

      return state;
    },

    deleteFromRecentViewProperty: (state, { payload }: PayloadAction<string | number>) => {
      state.recent_properties = state.recent_properties.filter((recent) => recent.id !== payload);
    },

    setSelectedProperty: (state, { payload }: PayloadAction<string>) => {
      state.selectedProperty = payload;
    },

    addAmenities: (state, { payload }: PayloadAction<string>) => {
      if (payload !== null && payload !== "") {
        state.amenities = [...state.amenities, payload];
      }
    },

    addPropertyRules: (state, { payload }: PayloadAction<string>) => {
      if (payload !== null && payload !== "") {
        state.property_rules = state.property_rules || [];
        if (!state.property_rules.includes(payload)) {
          state.property_rules = [...state.property_rules, payload];
        }
      }
    },
    removePropertyRules: (state, { payload }: PayloadAction<string>) => {
      state.property_rules = state.property_rules.filter((rule) => rule !== payload);
    },
    removeAllPropertyRules: (state) => {
      state.property_rules = [];
    },

    addSelectedAmenities: (state, { payload }: PayloadAction<string>) => {
      const isSelected = state.selectedAmenities.includes(payload);
      if (!isSelected) {
        state.selectedAmenities.push(payload); // Add amenity if not already selected
      } else {
        state.selectedAmenities = state.selectedAmenities.filter((item) => item !== payload); // Remove amenity if already selected
      }
    },
    addPropertyImages: (state, { payload }: PayloadAction<string | string[]>) => {
      const newImages = Array.isArray(payload) ? payload : [payload];
      const uniqueImages = newImages.filter((image) => !state.propertPhotosUrl.includes(image));
      state.propertPhotosUrl = [...state.propertPhotosUrl, ...uniqueImages];
    },

    removePropertyImages: (state, { payload }: PayloadAction<string>) => {
      state.propertPhotosUrl = state.propertPhotosUrl.filter((image, i) => image !== payload);
    },
    removeAllPropertyImages: (state) => {
      state.propertPhotosUrl = [];
    },

    addKitchen: (state) => ({ ...state, Kitchen: state.Kitchen + 1 }),

    minusKitchen: (state) => {
      if (state.Kitchen > 0) {
        return { ...state, Kitchen: state.Kitchen - 1 };
      }
      return state;
    },

    addBedroom: (state) => ({ ...state, bedrooms: state.bedrooms + 1 }),

    minusBedroom: (state) => {
      if (state.bedrooms > 0) {
        return { ...state, bedrooms: state.bedrooms - 1 };
      }
      return state;
    },

    addLivingRoom: (state) => ({ ...state, livingRoom: state.livingRoom + 1 }),

    minusLivingRoom: (state) => {
      if (state.livingRoom > 0) {
        return { ...state, livingRoom: state.livingRoom - 1 };
      }
      return state;
    },
    addBathroom: (state) => ({
      ...state,
      bathroom: state.bathroom + 1,
    }),
    setMinValue: (state, { payload }: PayloadAction<any>) => ({
      ...state,
      minPrice: payload,
    }),
    setMaxValue: (state, { payload }: PayloadAction<any>) => ({
      ...state,
      maxPrice: payload,
    }),
    setRooms: (state, { payload }: PayloadAction<any>) => ({
      ...state,
      rooms: payload,
    }),

    minusBathroom: (state) => {
      if (state.bathroom > 0) {
        return { ...state, bathroom: state.bathroom - 1 };
      }
      return state;
    },

    createPropertyMessage: (state, { payload }: PayloadAction<string>) => {
      state.property_message = payload;
    },
    createPropertyError: (state, { payload }: PayloadAction<string>) => {
      state.property_error = payload;
    },
    clearPropertyMessage: (state) => {
      state.property_message = null;
    },
    clearPropertyError: (state) => {
      state.property_error = null;
    },
    resetPropertyState: () => initialState,
    clearInputs: (state) => {
      state.selectedAmenities = [];
      state.bathroom = 0;
      state.bedrooms = 0;
      state.Kitchen = 0;
      state.livingRoom = 0;
      state.rooms = 0;
      state.maxPrice = 200000;
      state.minPrice = 50000;
    },
  },
  extraReducers: (builder) => {
    // Get Properties
    builder.addCase(getProperties.pending, (state) => {
      state.get_property_loading = true;
      state.property_error = null; // Clear property_error on pending
    });
    builder.addCase(getProperties.fulfilled, (state, { payload }) => {
      state.properties = payload?.propertyListings;
      state.currentPropertyPage = payload?.currentPage;
      state.totalPropertyPages = payload?.totalPages;
      state.totalPropertyItems = payload?.totalItems;
      state.get_property_loading = false;
    });
    builder.addCase(getProperties.rejected, (state, { payload }) => {
      state.get_property_loading = false;
      state.property_error = payload as string; // Assuming payload is string type (adjust if needed)
    });

    // Get searched properties
    builder.addCase(getSearch.pending, (state) => {
      state.get_search_loading = true;
      state.property_error = null; // Clear property_error on pending
    });
    builder.addCase(getSearch.fulfilled, (state, { payload }) => {
      state.searchedProperties = payload?.propertyListings;
      state.currentPropertyPage = payload?.currentPage;
      state.totalPropertySearchPages = payload?.totalPages;
      state.totalPropertyItems = payload?.totalItems;
      state.get_search_loading = false;
    });
    builder.addCase(getSearch.rejected, (state, { payload }) => {
      state.get_search_loading = false;
      state.property_error = payload as string; // Assuming payload is string type (adjust if needed)
    });
    // Get booked properties
    builder.addCase(getPropertyListingsBooked.pending, (state) => {
      state.get_booked_property_loading = true;
      state.property_error = null; // Clear property_error on pending
    });
    builder.addCase(getPropertyListingsBooked.fulfilled, (state, { payload }) => {
      state.booked_properties = payload?.propertyListings;
      state.currentBookedPage = payload?.currentPage;
      state.totalBookedPages = payload?.totalPages;
      state.totalBookedItems = payload?.totalCount;
      state.get_booked_property_loading = false;
    });
    builder.addCase(getPropertyListingsBooked.rejected, (state, { payload }) => {
      state.get_booked_property_loading = false;
      state.property_error = payload as string; // Assuming payload is string type (adjust if needed)
    });
    // Get live properties
    builder.addCase(getPropertyListingsLive.pending, (state) => {
      state.get_live_property_loading = true;
      state.property_error = null; // Clear property_error on pending
    });
    builder.addCase(getPropertyListingsLive.fulfilled, (state, { payload }) => {
      state.live_properties = payload?.propertyListings;
      state.currentLivePage = payload?.currentPage;
      state.totalLivePages = payload?.totalPages;
      state.totalLiveItems = payload?.totalCount;
      state.get_live_property_loading = false;
    });
    builder.addCase(getPropertyListingsLive.rejected, (state, { payload }) => {
      state.get_live_property_loading = false;
      state.property_error = payload as string; // Assuming payload is string type (adjust if needed)
    });

    // Get draft properties
    builder.addCase(getPropertyListingsDraft.pending, (state) => {
      state.get_draft_property_loading = true;
      state.property_error = null; // Clear property_error on pending
    });
    builder.addCase(getPropertyListingsDraft.fulfilled, (state, { payload }) => {
      state.draft_properties = payload?.propertyListings;
      state.currentDraftPage = payload?.currentPage;
      state.totalDraftPages = payload?.totalPages;
      state.totalDraftItems = payload?.totalCount;
      state.get_draft_property_loading = false;
    });
    builder.addCase(getPropertyListingsDraft.rejected, (state, { payload }) => {
      state.get_draft_property_loading = false;
      state.property_error = payload as string; // Assuming payload is string type (adjust if needed)
    });

    // Get pending properties
    builder.addCase(getPropertyListingsPending.pending, (state) => {
      state.get_pending_property_loading = true;
      state.property_error = null; // Clear property_error on pending
    });
    builder.addCase(getPropertyListingsPending.fulfilled, (state, { payload }) => {
      state.pending_properties = payload?.propertyListings;
      state.currentDraftPage = payload?.currentPage;
      state.totalDraftPages = payload?.totalPages;
      state.totalDraftItems = payload?.totalCount;
      state.get_pending_property_loading = false;
    });
    builder.addCase(getPropertyListingsPending.rejected, (state, { payload }) => {
      state.get_pending_property_loading = false;
      state.property_error = payload as string; // Assuming payload is string type (adjust if needed)
    });

    // Get Similar Property Listings (similar structure as getProperties)
    builder.addCase(getSimilarPropertyListings.pending, (state) => {
      state.get_similar_property_loading = true;
      state.property_error = null;
    });
    builder.addCase(getSimilarPropertyListings.fulfilled, (state, { payload }) => {
      state.similar_properties = payload; // Adjust property name if needed
      state.get_similar_property_loading = false;
    });
    builder.addCase(getSimilarPropertyListings.rejected, (state, { payload }) => {
      state.get_similar_property_loading = false;
      state.property_error = payload as string;
    });

    // Update Property
    builder.addCase(updateProperty.pending, (state) => {
      state.update_property_loading = true;
      state.property_error = null;
    });
    builder.addCase(updateProperty.fulfilled, (state, { payload }) => {
      state.properties = state.properties.map((property) => (property.id === payload?.id ? payload : property));
      state.update_property_loading = false;
    });
    builder.addCase(updateProperty.rejected, (state, { payload }) => {
      state.update_property_loading = false;
      state.property_error = payload as string;
    });

    // Create Property
    builder.addCase(createProperty.pending, (state) => {
      state.create_property_loading = true;
      state.create_approval_property_loading = true;
      state.property_error = null;
    });
    builder.addCase(createProperty.fulfilled, (state, { payload }) => {
      // state.properties = [...state.properties, payload];
      state.create_approval_property_loading = false;
      state.create_property_loading = false;
    });
    builder.addCase(createProperty.rejected, (state, { payload }) => {
      state.create_property_loading = false;
      state.create_approval_property_loading = false;
      state.property_error = payload as string;
    });

    // Property view
    builder.addCase(propertyViews.pending, (state) => {
      state.property_error = null;
    });
    builder.addCase(propertyViews.fulfilled, (state, { payload }) => {
      state.create_property_loading = false;
    });
    builder.addCase(propertyViews.rejected, (state, { payload }) => {
      state.property_error = payload as string;
    });
  },
});

export const {
  setMinValue,
  setMaxValue,
  setRooms,
  addAmenities,
  clearInputs,
  addPropertyImages,
  addBathroom,
  addBedroom,
  addKitchen,
  addLivingRoom,
  minusBathroom,
  minusBedroom,
  minusKitchen,
  minusLivingRoom,
  setRecentViewProperty,
  createPropertyMessage,
  createPropertyError,
  addSelectedAmenities,
  clearPropertyError,
  clearPropertyMessage,
  removePropertyImages,
  setSelectedProperty,
  resetPropertyState,
  setCurrentPropertyStatus,
  setSelectedCity,
  deleteFromRecentViewProperty,
  removeAllPropertyImages,
  addPropertyRules,
  removePropertyRules,
  removeAllPropertyRules,
  removeSelectedCity,
} = propertySlice.actions;

export default propertySlice.reducer;

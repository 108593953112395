export const getLocalStorageToken = () => {
  const token: string | null = localStorage.getItem("rentify");
  if (token !== null && typeof token === "string") {
    return token !== null && token !== undefined && token !== "undefined"
      ? JSON.parse(token)
      : null;
  }
};

export const setLocalStorageToken = (token: string) => {
  if (token) {
    localStorage.setItem("rentify", JSON.stringify(token));
  }
};

export const removeLocalStorageToken = () => {
  localStorage.removeItem("rentify");
};

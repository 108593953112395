import React, { Suspense } from "react";
import Loader from "./Loader";

interface SupensingProps {
  Component: React.ComponentType<any>;
  fallback?: React.ReactNode; // Allow customizing fallback
}

const Supensing: React.FC<SupensingProps> = ({ Component, fallback }) => {
  return (
    <Suspense fallback={fallback || <Loader />}>
      <Component />
    </Suspense>
  );
};

export default Supensing;

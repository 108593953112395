import { image3, image5, person, person2, person3, search, search1, search2, search3 } from "../constants";

import { CalendarDaysIcon, ChatBubbleOvalLeftEllipsisIcon, ShieldCheckIcon } from "@heroicons/react/24/solid";
import ChairIcon from "@mui/icons-material/Chair";

export const Links = [
  {
    id: 1,
    title: "Home",
    link: "/home",
  },
  {
    id: 2,
    title: "Landlords/Agents",
    link: "/register",
  },
  // {
  //   id: 3,
  //   title: "About us",
  //   link: "/about",
  // },
  {
    id: 3,
    title: "Contact us",
    link: "/contact",
  },
];

export const persons = [
  {
    id: 1,
    title: "Giovani Bacardo",
    subtitle: "title",
    image: person,
  },
  {
    id: 2,
    title: "Marianne Loreno",
    subtitle: "title",
    image: person2,
  },
  {
    id: 3,
    title: "Riga Pelore",
    subtitle: "title",
    image: person3,
  },
];

export const images = [
  {
    id: 1,
    image: image3,
  },
  {
    id: 2,
    image: image5,
  },
  {
    id: 3,
    image: search,
  },
  {
    id: 4,
    image: search1,
  },
  {
    id: 5,
    image: search2,
  },
  {
    id: 6,
    image: search3,
  },
];
export const bestChoicesTwo = [
  {
    id: 1,
    title: "Flexible living",
    subtitle: "Stay as Long or as little as you need with month-to-month contracts",
    icon: <CalendarDaysIcon className="h-10 w-10" />,
  },
  {
    id: 2,
    title: "Move-in ready",
    subtitle: "Ready to move in with everything you need",
    icon: <ChairIcon className="h-10 w-10 text-tertiary_color" style={{ fontSize: "25px" }} />,
  },
  {
    id: 3,
    title: "Stay safe!",
    subtitle: "Best in class internet speeds suitable for working from home",
    icon: <ShieldCheckIcon className="h-10 w-10 text-tertiary_color" />,
  },
  {
    id: 4,
    title: "12/7 support",
    subtitle: "On hand team for any issues you have",
    icon: <ChatBubbleOvalLeftEllipsisIcon className="h-10 w-10" />,
  },
];

export const bestChoices = [
  {
    id: 1,
    title: "Flexible living",
    subtitle: "Stay as Long or as little as you need with month-to-month contracts",
    icon: <CalendarDaysIcon className="h-10 w-10 text-white" />,
  },
  {
    id: 2,
    title: "Move-in ready",
    subtitle: "Ready to move in with everything you need",
    icon: <ChairIcon className="h-10 w-10 text-white" style={{ fontSize: "25px" }} />,
  },
  {
    id: 3,
    title: "Stay safe!",
    subtitle: "Best in class internet speeds suitable for working from home",
    icon: <ShieldCheckIcon className="h-10 w-10 text-white" />,
  },
  {
    id: 4,
    title: "12/7 support",
    subtitle: "On hand team for any issues you have",
    icon: <ChatBubbleOvalLeftEllipsisIcon className="h-10 w-10 text-white" />,
  },
  {
    id: 5,
    title: "Flexible living",
    subtitle: "Stay as Long or as little as you need with month-to-month contracts",
    icon: <CalendarDaysIcon className="h-10 w-10 text-white" />,
  },
  {
    id: 6,
    title: "Move-in ready",
    subtitle: "Ready to move in with everything you need",
    icon: <ChairIcon className="h-10 w-10 text-white" style={{ fontSize: "35px" }} />,
  },
  {
    id: 7,
    title: "Stay safe!",
    subtitle: "Best in class internet speeds suitable for working from home",
    icon: <ShieldCheckIcon className="h-10 w-10 text-white" />,
  },
  {
    id: 8,
    title: "12/7 support",
    subtitle: "On hand team for any issues you have",
    icon: <ChatBubbleOvalLeftEllipsisIcon className="h-10 w-10 text-white" />,
  },
];

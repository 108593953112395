import React from "react";
import { addPropertyImages } from "../features/property/propertySlice";
import { user } from "../features/auth/types";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../utils/firebase";
import imageCompression from "browser-image-compression";

export default function useImageUpload(dispatch?: any) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [personalPhoto, setPersonalPhoto] = React.useState<any>(undefined);
  const [message, setMessage] = React.useState<string | null>(null);
  const [nationalIdPhoto, setNationalIdPhoto] = React.useState<any>(undefined);
  const [propertyImage, setPropertyImage] = React.useState<any>(null);
  const [uploadProfileComplete, setProfileUploadComplete] = React.useState<boolean>(false);
  const [uploadComplete, setUploadComplete] = React.useState<boolean>(false);

  const handleFirebasePersonalPicture = async (image: any, childName: string, email: string) => {
    setLoading(true);
    setUploadComplete(false);
    try {
      if (image) {
        // Compress the image
        const compressedImage = await imageCompression(image, {
          maxSizeMB: 0.1, // Maximum size of the compressed image in MB (200 KB)
          maxWidthOrHeight: 1920, // Maximum width or height of the compressed image
        });

        const filename = `${childName}_${email}.png`;
        const imageRef = ref(storage, `${childName}/${filename}`);

        uploadBytes(imageRef, compressedImage).then((snapshot) => {
          getDownloadURL(snapshot.ref)
            .then((url) => {
              setPersonalPhoto(url);
              setUploadComplete(true);
            })
            .then(() => {
              setLoading(false);
            });
        });
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
      if (!image) {
        setUploadComplete(false);
      }
    }
  };

  const handleFirebaseNationalIdPhoto = async (image: any, childName: string, email: string) => {
    setLoading(true);
    setProfileUploadComplete(false);
    try {
      if (image) {
        // Compress the image
        const compressedImage = await imageCompression(image, {
          maxSizeMB: 0.1, // Maximum size of the compressed image in MB (200 KB)
          maxWidthOrHeight: 1920, // Maximum width or height of the compressed image
        });

        const filename = `${childName}_${email}.png`;
        const imageRef = ref(storage, `${childName}/${filename}`);

        uploadBytes(imageRef, compressedImage).then((snapshot) => {
          getDownloadURL(snapshot.ref)
            .then((url) => {
              setNationalIdPhoto(url);
              setProfileUploadComplete(true);
            })
            .then(() => {
              setLoading(false);
            });
        });
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
      if (!image) {
        setProfileUploadComplete(false);
      }
    }
  };

  const handleFirebasePropertyImages = async (images: any[], childName: string, user: any) => {
    try {
      const uploadedUrls: string[] = [];
      if (images.length) {
        setLoading(true);

        const uploadTasks = images.map(async (image: any, i: number) => {
          // Compress the image
          const compressedImage = await imageCompression(image, {
            maxSizeMB: 0.1, // Maximum size of the compressed image in MB (200 KB)
            maxWidthOrHeight: 1920, // Maximum width or height of the compressed image
          });

          const filename = `${childName}_${user.id}_${Date.now()}_${i}.png`;
          const imageRef = ref(storage, `${childName}/${user.name}/${filename}`);

          const snapshot = await uploadBytes(imageRef, compressedImage);
          const url = await getDownloadURL(snapshot.ref);
          uploadedUrls.push(url);
          // if (i === images.length - 1) {
          // }
        });

        await Promise.all(uploadTasks);
      }
      if (uploadedUrls.length > 0) {
        dispatch(addPropertyImages(uploadedUrls));
        // setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error uploading file:", error);
    }
  };

  const handleFirebasePropertyImage = async (image: any, childName: string, user: user) => {
    setLoading(true);
    try {
      if (image) {
        // Compress the image
        const compressedImage = await imageCompression(image, {
          maxSizeMB: 0.1, // Maximum size of the compressed image in MB
          maxWidthOrHeight: 1920, // Maximum width or height of the compressed image
        });

        // Generate filename
        const filename = `${childName}_${user.id}_${Date.now()}.png`;

        // Reference to Firebase Storage
        const imageRef = ref(storage, `${childName}/${user.name}/${filename}`);

        // Upload compressed image
        uploadBytes(imageRef, compressedImage).then((snapshot) => {
          // Get download URL of the uploaded image
          getDownloadURL(snapshot.ref)
            .then((url) => {
              setPropertyImage(url);
            })
            .then(() => {
              setLoading(false);
            });
        });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error uploading file:", error);
    }
  };

  return {
    loading,
    personalPhoto,
    message,
    nationalIdPhoto,
    propertyImage,
    setLoading,
    setMessage,
    setPropertyImage,
    uploadProfileComplete,
    uploadComplete,
    setProfileUploadComplete,
    setUploadComplete,
    handleFirebasePersonalPicture,
    handleFirebaseNationalIdPhoto,
    handleFirebasePropertyImage,
    handleFirebasePropertyImages,
  };
}

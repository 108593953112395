import { createAsyncThunk } from "@reduxjs/toolkit";
import { rentifyApi, tokenConfig } from "../../api";
import { createBookingMessage } from "./bookSlice";

export const getBookings = createAsyncThunk(
  "booking/get",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { data } = await rentifyApi.get(
        "/property-listings",
        tokenConfig(getState)
      );
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error.message);
      } else {
        return rejectWithValue(error.response.data.error.message);
      }
    }
  }
);

export const createBooking = createAsyncThunk(
  "booking/create",
  async (bookingData: any, { getState, rejectWithValue, dispatch }) => {
    const { propertyId, booking } = bookingData;
    try {
      const { data } = await rentifyApi.post(
        `/bookings/${propertyId}`,
        booking,
        tokenConfig(getState)
      );
      dispatch(createBookingMessage("Booking successfully created"));
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import useImageUpload from "../hooks/useImageUpload";
import Spinner from "./Spinner";
import useApp from "../hooks/useApp";

type DropZoneProps = {
  styles?: string;
  icon?: React.ReactElement;
};

export default function DropZone({ styles, icon }: DropZoneProps) {
  const { userInfo, dispatch } = useApp();
  const { handleFirebasePropertyImages } = useImageUpload(dispatch);
  const [loading, setLoading] = React.useState(false);
  const onDrop = useCallback(
    (acceptedFiles: any) => {
      setLoading(true);
      if (acceptedFiles?.length && userInfo) {
        handleFirebasePropertyImages(acceptedFiles, "properties", userInfo);
        // setLoading(false);
      }
    },
    [handleFirebasePropertyImages, userInfo]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <div>
      <div className={`${styles}`} {...getRootProps()}>
        <input {...getInputProps()} />
        {icon && icon}
        {isDragActive ? (
          <p className="text-[15px] text-black ">Drop the files here</p>
        ) : (
          <p className="text-[13px] text-black ">
            Drag and drop some files here, or click to select files.
          </p>
        )}
      </div>
      {loading && (
        <div className="flex items-center space-x-3">
          <Spinner styles="h-4 w-4 border-2 text-primary_color" />
          <p className="text-[13px] text-success_color">
            Uploading multiple photos...
          </p>
        </div>
      )}
    </div>
  );
}

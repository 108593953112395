import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createBooking, getBookings } from "./bookAction";
import { BookingState } from "./types";

const initialState: BookingState = {
  booking_message: null,
  booking_error: null,
  get_booking_loading: false,
  create_booking_loading: false,
  bookings: [],
};

const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    createBookingMessage: (state, { payload }: PayloadAction<string>) => {
      state.booking_message = payload;
    },
    createBookingError: (state, { payload }: PayloadAction<string>) => {
      state.booking_error = payload;
    },
    clearBookingMessage: (state) => {
      state.booking_message = null;
    },
    clearBookingError: (state) => {
      state.booking_error = null;
    },
  },
  extraReducers: (builder) => {
    // Get Properties
    builder.addCase(getBookings.pending, (state) => {
      state.get_booking_loading = true;
      state.booking_error = null; // Clear booking_error on pending
    });
    builder.addCase(getBookings.fulfilled, (state, { payload }) => {
      state.bookings = payload;
      state.get_booking_loading = false;
    });
    builder.addCase(getBookings.rejected, (state, { payload }) => {
      state.get_booking_loading = false;
      state.booking_error = payload as string; // Assuming payload is string type (adjust if needed)
    });
    // Create book
    builder.addCase(createBooking.pending, (state) => {
      state.create_booking_loading = true;
      state.booking_error = null;
    });
    builder.addCase(createBooking.fulfilled, (state, { payload }) => {
      // state.Booking = [...state.Booking, payload];
      state.create_booking_loading = false;
    });
    builder.addCase(createBooking.rejected, (state, { payload }) => {
      state.create_booking_loading = false;
      state.booking_error = payload as string;
    });
  },
});

export const {
  createBookingMessage,
  createBookingError,
  clearBookingError,
  clearBookingMessage,
} = bookingSlice.actions;

export default bookingSlice.reducer;

import React from "react";
import { errorType, ForgotPasswordType, LoginType, RegisterType, ResetPasswordType } from "./types";
import { useDispatch } from "react-redux";
import { clearError } from "../features/auth/authSlice";
import { user } from "../features/auth/types";

const initialLoginState: LoginType = {
  email: "",
  password: "",
};

const passwordResetData: ResetPasswordType = {
  password: "",
  confirm_password: "",
};

const forgotPasswordData: ForgotPasswordType = {
  email: "",
};

const initialRegisterState: RegisterType = {
  name: "",
  email: "",
  phoneNumber: "",
  password: "",
  nationalIDnumber: "none",
  confirmPassword: "",
};
const valueData: user = {
  email: "",
  name: "",
  phoneNumber: "",
  nationalId: "",
  nationalPhotoId: "",
};

export default function useForm(validateOnChange = false, auth?: string) {
  const dispatch = useDispatch();
  const [values, setValues] = React.useState<LoginType>(initialLoginState);
  const [registerValues, setRegisterValues] = React.useState<RegisterType>(initialRegisterState);
  const [profileValues, setProfileValues] = React.useState(valueData);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [forgotValues, setForgotValues] = React.useState<ForgotPasswordType>(forgotPasswordData);
  const [resetValues, setResetValues] = React.useState<ResetPasswordType>(passwordResetData);
  const [errors, setErrors] = React.useState<errorType>({});
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    if (name === "email") {
      window.localStorage.setItem("email", value);
    }

    setValues({
      ...values,
      [name]: value,
    });

    setRegisterValues({
      ...registerValues,
      [name]: value,
    });

    setForgotValues({
      ...forgotValues,
      [name]: value,
    });

    setResetValues({
      ...resetValues,
      [name]: value,
    });

    setProfileValues({
      ...profileValues,
      [name]: value,
    });

    if (validateOnChange) {
      if (auth === "login") {
        validateLogin();
      }
    }
    dispatch(clearError());
  };

  const validateForgotPassword = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    let temp: errorType = {};

    if (!forgotValues.email) {
      temp.email = "Email is required";
    } else if (!emailRegex.test(forgotValues.email)) {
      temp.email = "Invalid email format";
    } else {
      temp.email = "";
    }

    setErrors({ ...temp });
    return Object.values(temp).every((x) => !x);
  };

  const validateResetPassword = () => {
    let temp: errorType = {};
    // Password validation
    if (!resetValues.password) {
      temp.password = "Password is required";
    } else if (resetValues.password.length < 6) {
      temp.password = "Password should contain at least 6 characters";
    } else {
      temp.password = "";
    }

    // Confirm password validation
    if (resetValues.password !== resetValues.confirm_password) {
      temp.confirm_password = "Passwords do not match";
    } else {
      temp.confirm_password = "";
    }
    setErrors({ ...temp });
    return Object.values(temp).every((x) => !x);
  };

  const validateLogin = () => {
    let temp: errorType = {};
    temp.password = values.password
      ? values.password.length < 6
        ? "Password should contain at least 6 characters"
        : ""
      : "Password is required";
    temp.email = values.email ? "" : "Email or phone number is required";
    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const validateRegister = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    let temp: errorType = {};

    // Password validation
    if (!registerValues.password) {
      temp.password = "Password is required";
    } else if (registerValues.password.length < 6) {
      temp.password = "Password should contain at least 6 characters";
    } else {
      temp.password = "";
    }

    // Confirm password validation
    if (registerValues.password !== registerValues.confirmPassword) {
      temp.confirmPassword = "Passwords do not match";
    } else {
      temp.confirmPassword = "";
    }

    temp.email = emailRegex.test(registerValues.email || "") ? "" : "Invalid email format";
    temp.phone_number = registerValues.phoneNumber.length === 10 ? "" : "Maximum of ten phone number digits required";
    // temp.national_id =
    //   registerValues.nationalIDnumber.length === 14
    //     ? ""
    //     : "Maximum of 14 Nin number characters required";
    temp.name = registerValues.name ? "" : "Name is required";
    setErrors({ ...temp });
    return Object.values(temp).every((x) => !x);
  };

  const handleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleClearForm = () => {
    setValues(initialLoginState);
    setErrors({});
  };

  return {
    values,
    errors,
    showPassword,
    profileValues,
    registerValues,
    forgotValues,
    resetValues,
    dispatch,
    setErrors,
    setValues,
    setProfileValues,
    validateLogin,
    handleClearForm,
    validateRegister,
    handleInputChange,
    handleShowPassword,
    validateForgotPassword,
    validateResetPassword,
  };
}

import {
  add,
  eachDayOfInterval,
  endOfMonth,
  format,
  getDay,
  isToday,
  startOfMonth,
  sub,
} from "date-fns";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/solid";
import { useState } from "react";
// create week days
const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

interface Event {
  date: Date;
  title: string;
  bgColor?: string;
}

interface EventCalendarProps {
  events?: Event[];
  onChange?: (value: Date) => void;
}

export default function EventCalendar({ onChange }: EventCalendarProps) {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [active, setActive] = useState<number | null>(null);
  const lastDayOfMonth = endOfMonth(currentDate);
  const firstDayOfMonth = startOfMonth(currentDate);

  const daysInMonth = eachDayOfInterval({
    start: firstDayOfMonth,
    end: lastDayOfMonth,
  });

  const startingDayIndex = getDay(firstDayOfMonth);
  const endingDayIndex = getDay(lastDayOfMonth);

  const prevMonth = () => setCurrentDate(sub(currentDate, { months: 1 }));
  const nextMonth = () => setCurrentDate(add(currentDate, { months: 1 }));
  const prevYear = () => setCurrentDate(sub(currentDate, { years: 1 }));
  const nextYear = () => setCurrentDate(add(currentDate, { years: 1 }));

  // const handleChangeDate = (i: number) => {
  //   console.log(i);
  //   const clickedDate = setDate(currentDate, i);
  //   onChange && onChange(clickedDate);
  // };
  const handleChangeDate = (date: Date, i: number) => {
    setActive(i);
    onChange && onChange(date);
  };
  //   const eventsByDate = useMemo(() => {
  //     if (!events) return {};

  //     return events.reduce((acc: { [key: string]: Event[] }, event) => {
  //       const dateKey = format(event.date, "yyyy-MM-dd");
  //       if (!acc[dateKey]) {
  //         acc[dateKey] = [];
  //       }
  //       acc[dateKey].push(event);
  //       return acc;
  //     }, {});
  //   }, [events]);

  return (
    <div className="bg-[#FFF2F2] border border-[#FFF2F2] rounded-md flex flex-col space-y-1 p-6">
      <div className="w-full flex items-center justify-between">
        <div className="w-full flex items-center justify-between">
          <div className="flex items-center space-x-3 sm:space-x-6">
            <div
              onClick={prevMonth}
              className="p-1 bg-background_color_second rounded-full cursor-pointer"
            >
              <ChevronLeftIcon className="w-3 h-3" />
            </div>
            <p className="font-semibold text-[15px]">
              {format(currentDate, "MMMM yyyy")}
            </p>
            <div
              onClick={nextMonth}
              className="p-1 bg-background_color_second rounded-full cursor-pointer"
            >
              <ChevronRightIcon className="w-3 h-3" />
            </div>
          </div>
          <div className="flex items-center space-x-2 sm:space-x-6 sm:px-2">
            <div className="flex flex-col space-y-1">
              <div
                className="cursor-pointer px-1 rounded-sm bg-background_color_second"
                onClick={nextYear}
              >
                <ChevronUpIcon className="w-4 h-4" />
              </div>
              <div
                className="cursor-pointer px-1 rounded-sm bg-background_color_second"
                onClick={prevYear}
              >
                <ChevronDownIcon className="w-4 h-4" />
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center space-x-5"></div>
      </div>
      <div className="w-full grid grid-cols-7">
        {weekDays.map((day) => {
          return (
            <div key={day} className="py-3 flex ">
              <p className="text-[12px] text-center font-semibold">{day}</p>
            </div>
          );
        })}
        {Array.from({ length: startingDayIndex }).map((_, i) => {
          return <div className="" key={`empty-${i}`} />;
        })}
        {daysInMonth.map((day, i) => {
          return (
            <div
              onClick={() => handleChangeDate(day, i)}
              key={i}
              className={`cursor-pointer hover:bg-[#a8b3cf1f] ${
                active === i && "bg-gradient-to-r from-linear_to_color to-linear_from_color"
              }  p-2 flex flex-col items-start`}
            >
              <p
                className={`text-[12px] ${
                  isToday(day) &&
                  "bg-gradient-to-r from-linear_to_color to-linear_from_color rounded-full p-2 text-white"
                }`}
              >
                {format(day, "d")}
              </p>
              {/* {isToday(day) && <p className="text-[12px]">Today</p>} */}
              {/* {todaysEvents.map((event, index) => (
                <p className="text-[13px]" key={index}>
                  {event.title}
                  {}
                </p>
              ))} */}
              {/* ${hasEvents ? todaysEvents[0].bgColor : ""} */}
            </div>
          );
        })}
        {Array.from({ length: endingDayIndex }).map((_, i) => {
          return <div className="" key={`empty-${i}`} />;
        })}
      </div>
    </div>
  );
}

import React, { ChangeEvent } from "react";

type Props = {
  label?: string;
  name?: string;
  placeholder?: string;
  value?: string;
  styles?: string;
  rows?: number;
  onChange?: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  error?: string | null | boolean;
};

export default function TextArea({
  label,
  name,
  placeholder,
  value,
  onChange,
  styles,
  rows,
  error,
}: Props) {
  return (
    <div className="flex flex-col space-y-1">
      <div className={`h-full flex ${styles}`}>
        {label && (
          <label className="block mb-2 text-sm font-medium text-gray-900 ">
            {label}
          </label>
        )}
        <textarea
          className="h-full flex-1 w-full rounded border text-[16px] border-none outline-none bg-transparent px-3 py-[0.32rem]"
          rows={rows}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          name={name}
          style={{ height: "100%" }}
        />
      </div>
      {error && <p className="text-primary_color text-xs">{error}</p>}
    </div>
  );
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "./types";
import { getCities } from "./appActions";

const initialState: AppState = {
  currentItem: null,
  currentPosition: "Overview",
  displaySearch: false,
  dropDown: false,
  menuDown: false,
  image: null,
  checked: false,
  isOpen: false,
  currentProperty: "Live property",
  phone: false,
  districts: [],
  get_districts_loading: false,
  get_district_error: null,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setCurrentItem: (state, { payload }: PayloadAction<string>) => {
      state.currentItem = payload;
    },
    setCurrentPosition: (state, { payload }: PayloadAction<string>) => {
      state.currentPosition = payload;
    },
    setCurrentProperty: (state, { payload }: PayloadAction<string>) => {
      state.currentProperty = payload;
    },
    setDisplaySearch: (state, { payload }: PayloadAction<boolean>) => {
      state.displaySearch = payload;
    },
    setDropDown: (state, { payload }: PayloadAction<boolean>) => {
      state.dropDown = payload;
    },
    setMenuDown: (state, { payload }: PayloadAction<boolean>) => {
      state.menuDown = payload;
    },
    addImage: (state, { payload }: PayloadAction<string>) => {
      state.image = payload;
    },
    removeImage: (state) => {
      state.image = null;
    },
    setChecked: (state, { payload }: PayloadAction<boolean>) => {
      state.checked = payload;
    },
    onOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpen = payload;
    },
    onClose: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpen = payload;
    },
    setPhone: (state, { payload }: PayloadAction<boolean>) => {
      state.phone = payload;
    },
  },
  
  extraReducers: (builder) => {
    // Get Properties
    builder.addCase(getCities.pending, (state) => {
      state.get_districts_loading = true;
      state.get_district_error = null;
    });
    // builder.addCase(getCities.fulfilled, (state, { payload }) => {
    //   if (payload) {
    //     state.districts = payload;
    //     state.get_districts_loading = false;
    //   }
    // });

    builder.addCase(getCities.fulfilled, (state, { payload }) => {
      if (payload) {
        // Assert the type of payload to string[]
        const districts: string[] = payload as string[];

        // Create a Set from the payload array to remove duplicates
        const uniqueDistrictsSet = new Set(districts);

        // Convert the Set back to an array
        const uniqueDistrictsArray = Array.from(uniqueDistrictsSet);

        state.districts = uniqueDistrictsArray;
        state.get_districts_loading = false;
      }
    });

    builder.addCase(getCities.rejected, (state, { payload }) => {
      state.get_districts_loading = false;
      state.get_district_error = payload as string; // Assuming payload is string type (adjust if needed)
    });
  },
});

export const {
  setDisplaySearch,
  setDropDown,
  addImage,
  removeImage,
  setChecked,
  setMenuDown,
  onClose,
  onOpen,
  setCurrentItem,
  setCurrentProperty,
  setPhone,
  setCurrentPosition,
} = appSlice.actions;

export default appSlice.reducer;

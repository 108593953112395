import { ChevronLeftIcon, ChevronRightIcon, XMarkIcon } from "@heroicons/react/24/solid";

export default function ImageGallery({
  data,
  openModal,
  Close,
  slideNumber,
  setSlideNumber,
}: {
  data: any;
  openModal: boolean;
  Close: () => void;
  slideNumber: number;
  setSlideNumber: any;
}) {
  const handleOpenModal = (index: any) => {
    setSlideNumber(index);
  };

  const handleCloseModal = () => {
    Close();
  };

  const prevSlide = () => {
    slideNumber === 0 ? setSlideNumber(data?.split(",").length - 1) : setSlideNumber(slideNumber - 1);
  };

  const nextSlide = () => {
    slideNumber + 1 === data?.split(",").length ? setSlideNumber(0) : setSlideNumber(slideNumber + 1);
  };

  return (
    <div className="overflow-hidden">
      {openModal && (
        <div className="fixed inset-0 z-999 bg-black flex items-center justify-center w-full h-full">
          <div
            onClick={handleCloseModal}
            className="flex items-center space-x-4 fixed cursor-pointer opacity-60 z-9999 hover:opacity-100 top-[40px] left-[13%] right-[13%]"
          >
            <XMarkIcon className="h-7 w-7 text-white" />
            <p className="text-white text-[16px]">Close</p>
          </div>
          <div
            onClick={prevSlide}
            className="fixed cursor-pointer bg-white rounded-full p-2.5 z-9999  top-[50%] transform translate-y-[-50%] left-[13%]"
          >
            <ChevronLeftIcon className="h-4 w-4" />
          </div>
          <div
            onClick={nextSlide}
            className="fixed cursor-pointer bg-white z-9999 rounded-full p-2.5 top-[50%] transform translate-y-[-50%] right-[13%] "
          >
            <ChevronRightIcon className="h-4 w-4" />
          </div>
          <div className="flex items-center justify-center w-[50%]">
            <img
              src={data?.split(",")[slideNumber]?.trim()}
              alt=""
              className="object-contain h-full w-full pointer-events-none select-none rounded-md"
            />
          </div>
          <p className="fixed bottom-30 text-center text-white text-[16px]">
            {slideNumber}/{data?.split(",").length}
          </p>
          <div className="fixed left-[25%] bottom-7 flex items-center justify-center">
            {data &&
              data.split(",").map((slide: any, i: any) => (
                <div
                  key={i}
                  className="h-22 w-22 mr-3 flex items-center space-x-3 cursor-pointer rounded-md bg-blue-gray-700"
                  onClick={() => handleOpenModal(i)}
                >
                  <img src={slide} alt="img" className="h-full w-full object-contain rounded-md " />
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}

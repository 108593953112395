import React, { useState } from "react";
import { Divide } from "./Divider";
import { Link } from "react-router-dom";
import { Links } from "../data";
import Button from "./Button";
// import { logout } from "../features/auth/authSlice";
// import useApp from "../hooks/useApp";

type MobileNavProps = {
  showMobileMenu: boolean;
  setShowMobileMenu: () => void;
};

export default function MobileNavBar({
  showMobileMenu,
  setShowMobileMenu,
}: MobileNavProps) {
  // const { /*isAuthenticated,*/ dispatch, navigate } = useApp();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selected, setSelected] = useState<number>(0);

  return (
    <div
      className={`top-0 h-screen w-full fixed p-8 ${
        !showMobileMenu ? "-right-full opacity-0" : "right-0 opacity-100"
      } bg-white border transform transition-all duration-[800ms] shadow-xl z-10`}
    >
      <div className="h-full flex flex-col justify-between">
        <div className="flex flex-col space-y-6">
          <div className="flex flex-col">
            {Links.map((l) => (
              <div className="flex flex-col space-y-5" key={l.id}>
                <Divide />
                <Link
                  onClick={() => setSelected(l.id)}
                  to={l.link}
                  className={`cursor-pointer text-[15px] font-medium transition ease-in-out duration-[800ms] origin-left `}
                >
                  <p className="text-[14px] font-bold">{l.title}</p>
                </Link>
                <Divide />
              </div>
            ))}
          </div>
        </div>

        <div className="flex flex-col space-y-15">
          <div className="flex flex-col space-y-7">
            <div className="flex flex-col items-start">
              <p className="text-[14px] font-bold text-primary_color">
                Email us
              </p>
              <p className="text-[15px]">info@rentify.co.ug</p>
            </div>
            <div className="flex flex-col items-start">
              <p className="text-[14px] font-bold text-primary_color">
                Quick Help
              </p>
              <p className="text-[15px]">+256 200932870</p>
            </div>
          </div>
          <Button
            outline={true}
            text="Close Menu"
            onClick={setShowMobileMenu}
            buttonStyle="py-4 text-[14px] font-bold text-primary_color rounded-md cursor-pointer border-primary_color"
          />
        </div>
      </div>
    </div>
  );
}

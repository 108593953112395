const logo = require("../assets/images/logo.png");
const logo2 = require("../assets/images/logowhitebg.png");
const logo3 = require("../assets/images/pngTransparent.png");
const logo_landlord = require("../assets/images/logo_landlord.png");
const image6 = require("../assets/images/image6.jpeg");
const image3 = require("../assets/images/image3.jpeg");
const image7 = require("../assets/images/image7.jpeg");
const image8 = require("../assets/images/image8.jpeg");
const image5 = require("../assets/images/image5.jpeg");
const person = require("../assets/images/person.jpeg");
const person2 = require("../assets/images/person2.jpeg");
const person3 = require("../assets/images/person3.jpeg");
const about = require("../assets/images/about.jpeg");
const about2 = require("../assets/images/about2.jpeg");
const about3 = require("../assets/images/about3.jpeg");
const about4 = require("../assets/images/about4.jpeg");
const image = require("../assets/images/image.jpeg");
const landlord = require("../assets/images/landlord.png");
const landlord1 = require("../assets/images/landlord1.png");
const search = require("../assets/images/search.jpeg");
const search1 = require("../assets/images/search1.jpeg");
const search2 = require("../assets/images/search2.jpeg");
const search3 = require("../assets/images/search3.jpeg");
const asset = require("../assets/images/asset.png");
export {
  logo,
  logo2,
  logo3,
  logo_landlord,
  landlord,
  image3,
  image6,
  image7,
  image8,
  image5,
  image,
  about,
  about2,
  about3,
  about4,
  person,
  person2,
  person3,
  landlord1,
  search,
  search2,
  search3,
  search1,
  asset,
};
// Array containing districts of Uganda
export const ugandaDistricts = [
  "Abim",
  "Adjumani",
  "Agago",
  "Alebtong",
  "Amolatar",
  "Amudat",
  "Amuria",
  "Amuru",
  "Apac",
  "Arua",
  "Budaka",
  "Bududa",
  "Bugiri",
  "Buhweju",
  "Buikwe",
  "Bukedea",
  "Bukomansimbi",
  "Bukwo",
  "Bulambuli",
  "Buliisa",
  "Bundibugyo",
  "Bushenyi",
  "Busia",
  "Butaleja",
  "Butambala",
  "Butebo",
  "Buvuma",
  "Buyende",
  "Dokolo",
  "Gomba",
  "Gulu",
  "Hoima",
  "Ibanda",
  "Iganga",
  "Isingiro",
  "Jinja",
  "Kaabong",
  "Kabale",
  "Kabarole",
  "Kaberamaido",
  "Kagadi",
  "Kakumiro",
  "Kalangala",
  "Kaliro",
  "Kalungu",
  "Kampala",
  "Kamuli",
  "Kamwenge",
  "Kanungu",
  "Kapchorwa",
  "Kasese",
  "Katakwi",
  "Kayunga",
  "Kibaale",
  "Kiboga",
  "Kibuku",
  "Kigezi",
  "Kiruhura",
  "Kiryandongo",
  "Kisoro",
  "Kitgum",
  "Koboko",
  "Kole",
  "Kotido",
  "Kumi",
  "Kween",
  "Kyankwanzi",
  "Kyegegwa",
  "Kyenjojo",
  "Lamwo",
  "Lira",
  "Luuka",
  "Luwero",
  "Lwengo",
  "Lyantonde",
  "Manafwa",
  "Maracha",
  "Masaka",
  "Masindi",
  "Mayuge",
  "Mbale",
  "Mbarara",
  "Mitooma",
  "Mityana",
  "Moroto",
  "Moyo",
  "Mpigi",
  "Mubende",
  "Mukono",
  "Nakapiripirit",
  "Nakaseke",
  "Nakasongola",
  "Namayingo",
  "Namisindwa",
  "Namutumba",
  "Napak",
  "Nebbi",
  "Ngora",
  "Ntoroko",
  "Ntungamo",
  "Nwoya",
  "Otuke",
  "Oyam",
  "Pader",
  "Pallisa",
  "Rakai",
  "Rubanda",
  "Rubirizi",
  "Rukiga",
  "Rukungiri",
  "Sembabule",
  "Serere",
  "Sheema",
  "Sironko",
  "Soroti",
  "Tororo",
  "Wakiso",
  "Yumbe",
  "Zombo",
  "Nalya"
];

import React from "react";

export default function CustomStep({
  leftTitle,
  rightTitle,
}: {
  leftTitle?: React.ReactElement;
  rightTitle?: React.ReactElement;
}) {
  return (
    <div className="flex flex-col lg:flex-row relative">
      <div className="flex flex-col flex-1">{leftTitle}</div>

      <div className="flex flex-col items-center">
        <div className="w-3.5 h-3.5 rounded-full bg-black" />
        <div className="h-[180px] min-h-[1em] w-px self-stretch bg-black ml-1.5" />
      </div>

      <div className="flex flex-col flex-1">{rightTitle}</div>
    </div>
  );
}

import React from "react";

type Props = {
  text: string | React.ReactNode;
  icon?: React.ReactNode;
  type?: "button" | "submit" | "reset";
  onClick?: (e: any) => void;
  buttonStyle?: string;
  disabled?: boolean;
  outline?: boolean;
  small?: boolean;
  leftIcon?: React.ReactElement;
  radiusProp?: number;
};

export default function Button({
  text,
  buttonStyle,
  onClick,
  icon,
  type,
  disabled,
  outline,
  small,
  leftIcon,
  radiusProp,
}: Props) {
  return (
    <button
      style={{ borderRadius: radiusProp }}
      disabled={disabled}
      type={type}
      className={`${buttonStyle} text-[13px] disabled:opacity-70 disabled:cursor-not-allowed ${
        outline ? "bg-white border" : "bg-rose-500 border-rose-500 "
      } ${small ? "py-2 text-sm" : "py-3 text-md font-semibold"}`}
      onClick={onClick}
    >
      <div className="flex items-center space-x-4 justify-center">
        {leftIcon && <div>{leftIcon}</div>}
        {text && <div>{text}</div>}
        {icon && <div>{icon}</div>}
      </div>
    </button>
  );
}

import { createAsyncThunk } from "@reduxjs/toolkit";
import { rentifyApi, tokenConfig } from "../../api";
import { createPropertyMessage } from "./propertySlice";

export const getProperties = createAsyncThunk("property/get", async (page: any, { rejectWithValue }) => {
  try {
    const { data } = await rentifyApi.get(`/property-listings?page=${page}`);
    return data;
  } catch (error: any) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.response.data.message);
    }
  }
});

export const getSearch = createAsyncThunk("property-search/get", async (searchData: any, { rejectWithValue }) => {
  const { city, rooms, minPrice, maxPrice, page } = searchData;
  let apiUrl = "/home/search?";
  let isFirstParam = true;

  if (city !== undefined) {
    apiUrl += `place=${city}`;
    isFirstParam = false;
  }

  if (rooms !== undefined && rooms !== 0 && rooms !== "0") {
    apiUrl += `${isFirstParam ? "" : "&"}rooms=${rooms}`;
    isFirstParam = false;
  }

  if (minPrice !== undefined) {
    apiUrl += `${isFirstParam ? "" : "&"}minPrice=${minPrice}`;
    isFirstParam = false;
  }

  if (maxPrice !== undefined) {
    apiUrl += `${isFirstParam ? "" : "&"}maxPrice=${maxPrice}`;
    isFirstParam = false;
  }

  if (page !== undefined) {
    apiUrl += `${isFirstParam ? "" : "&"}page=${page}`;
    isFirstParam = false;
  }

  try {
    const { data } = await rentifyApi.get(apiUrl);
    return data;
  } catch (error: any) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue("An error occurred during the search.");
    }
  }
});

export const getSimilarPropertyListings = createAsyncThunk(
  "similar-property/get",
  async (id: any, { getState, rejectWithValue }) => {
    try {
      const { data } = await rentifyApi.get(`/property-listings/similar/${id}`, tokenConfig(getState));
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const propertyViews = createAsyncThunk("property-views/post", async (id: any, { getState, rejectWithValue }) => {
  try {
    const { data } = await rentifyApi.post(`/property-listings/${id}/view`, tokenConfig(getState));
    return data;
  } catch (error: any) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.response.data.message);
    }
  }
});

export const getPropertyListingsBooked = createAsyncThunk(
  "landlord-property-booked/get",
  async (bookedData: any, { getState, rejectWithValue }) => {
    const { landlordId, page } = bookedData;
    try {
      const { data } = await rentifyApi.get(`/landloard/booked/${landlordId}?page=${page}`, tokenConfig(getState));
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const getPropertyListingsDraft = createAsyncThunk(
  "landlord-property-draft/get",
  async (draftData: any, { getState, rejectWithValue }) => {
    const { landlordId, page } = draftData;
    try {
      const { data } = await rentifyApi.get(`/landloard/draft/${landlordId}?page=${page}`, tokenConfig(getState));
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const getPropertyListingsLive = createAsyncThunk(
  "landlord-property-live/get",
  async (liveData: any, { getState, rejectWithValue }) => {
    const { landlordId, page } = liveData;
    try {
      const { data } = await rentifyApi.get(`/landloard/live/${landlordId}?page=${page}`, tokenConfig(getState));
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const getPropertyListingsPending = createAsyncThunk(
  "landlord-property-pending/get",
  async (pendingData: any, { getState, rejectWithValue }) => {
    const { landlordId, page } = pendingData;
    try {
      const { data } = await rentifyApi.get(
        `/property-listings/pending/${landlordId}?page=${page}`,
        tokenConfig(getState)
      );
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const createProperty = createAsyncThunk(
  "property/create",
  async (propertyData: any, { getState, rejectWithValue, dispatch }) => {
    const { userId, property } = propertyData;
    try {
      const { data } = await rentifyApi.post(`/property-listings/${userId}`, property, tokenConfig(getState));
      dispatch(createPropertyMessage("Property successfully created"));
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      } else if (error.response && error.response.data.message) {
        if (error.response.data.message === "Internal server error") {
          dispatch(createPropertyMessage("Please complete the entire form before sending for approval"));
        }

        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const updateProperty = createAsyncThunk(
  "property/update",
  async (propertyData: any, { getState, rejectWithValue, dispatch }) => {
    const { property } = propertyData;
    try {
      const { data } = await rentifyApi.patch(`/property-listings/${property?.id}/`, property, tokenConfig(getState));
      dispatch(createPropertyMessage("Property updated successfully"));
      setTimeout(() => window.location.assign("/listings"), 1500);
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        if (error.response.data.message === "Internal server error") {
          dispatch(createPropertyMessage("Please complete the entire form before sending for approval"));
        }
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const deleteProperty = createAsyncThunk(
  "property/delete",
  async (id, { getState, rejectWithValue, dispatch }) => {
    try {
      await rentifyApi.delete(`/property-listings/${id}/`, tokenConfig(getState));
      dispatch(createPropertyMessage("Property deleted successfully"));
      return id;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

import { useEffect, useState } from "react";

type Props = {
  label?: string;
  type?: string;
  name?: string;
  placeholder?: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  iconRight?: React.ReactNode;
  iconLeft?: React.ReactNode;
  styles?: string;
  disabled?: boolean;
  error?: string | null | boolean;
  inputStyle?: string;
  onFocus?: VoidFunction;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  min?: number;
  hideInput?: string;
};

export default function Input({
  label,
  type,
  name,
  placeholder,
  value,
  onChange,
  iconRight,
  iconLeft,
  styles,
  disabled,
  inputStyle,
  error,
  onFocus,
  onKeyDown,
  min,
  hideInput,
}: Props) {
  const [focus, setFocus] = useState(false);

  const handleFocus = () => {
    if (onFocus) {
      onFocus();
    } else {
      setFocus(true);
    }
  };

  const handleBlur = () => {
    setFocus(false);
  };
  useEffect(() => {
    if (error) {
      window.scrollTo(0, 0);
    }
  }, [error]);
  return (
    <div className={`${hideInput}`}>
      <div className="flex items-center justify-between">
        <label className={`block mb-2 text-[15px] text-gray-900`}>{label}</label>
      </div>
      <div className="flex flex-col">
        <div
          className={`flex items-center ${styles} ${
            error
              ? "border-primary_color"
              : `${focus ? "outline-none border-primary_color ring-primary_color " : "border-stroke"}`
          } `}
        >
          {iconLeft && iconLeft}
          <input
            type={type}
            name={name}
            min={min}
            onKeyDown={onKeyDown}
            value={value}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={`h-full ${inputStyle} outline-none ${
              error ? "border-2 border-[#ff0000]" : ""
            } rounded text-gray-900 text-sm ${
              iconLeft ? "ml-4" : "mr-4"
            }  block w-full dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white`}
            placeholder={placeholder}
            disabled={disabled}
            onChange={onChange}
          />
          {iconRight && iconRight}
        </div>
        {error && <p className="text-primary_color text-sm">{error}</p>}
      </div>
    </div>
  );
}

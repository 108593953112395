import Modal from "./Modal";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import { CheckIcon } from "@heroicons/react/24/solid";
type Props = {
  isOpen: boolean;
  Close: () => void;
};

export default function ConfirmationModal({ isOpen, Close }: Props) {
  const navigate = useNavigate();
  const bodyContent = (
    <div className="flex flex-col items-center justify-center space-y-6 px-5 md:px-20 lg:px-10">
      <div className="flex flex-col space-y-2 items-center justify-center">
        <div className="h-17 w-17 rounded-full bg-[#EEE1FF] flex items-center justify-center">
          <CheckIcon className="w-7 h-7 text-[#682CD8]" />
        </div>
        <h5 className="text-[#682CD8] font-semibold text-[20px] md:text-[24px] lg:text-[30px] font-DmSan">
          Congratulations!
        </h5>
      </div>
      <p className="text-[15px] lg:text-[17px] 2xl:text-[18px] font-DmSan leading-6 text-center">
        Your form is being submitted, wait for our feedback which will be sent
        to your email address{" "}
      </p>
    </div>
  );

  const footerContent = (
    <div className="flex items-center justify-center">
      <Button
        small
        text="Okay, take me home"
        onClick={() => navigate("/home")}
        buttonStyle="w-[30%] py-4 lg:text-[16px] text-white font-bold bg-gradient-to-r rounded-3xl from-linear_from_color to-linear_to_color"
      />
    </div>
  );

  return (
    <Modal
      styles="p-6"
      isOpen={isOpen}
      actionLabel="Continue"
      onClose={Close}
      body={bodyContent}
      footer={footerContent}
      modalStyles="w-full md:w-3/6 2xl:w-2/6 my-6 mx-auto h-6/6 lg:h-auto md:h-auto"
    />
  );
}

import axios, { AxiosRequestConfig } from "axios";

export const rentifyApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const tokenConfig = (getState: any): AxiosRequestConfig => {
  const token = getState().auth.token;
  const Config: AxiosRequestConfig = {
    headers: {
      "Content-type": "application/json",
    },
  };

  if (token) {
    Config.headers!["Authorization"] = `Bearer ${token}`;
  }
  return Config;
};

export default axios;

import React from "react";
import { Link } from "react-router-dom";

type LogoType = {
  styles?: string;
  imageStyles?: string;
  logo: string;
};

export default function Logo({ logo, styles, imageStyles }: LogoType) {
  return (
    <Link to="/" className={`h-auto ${styles}`}>
      <img src={logo} alt="logo" className={`w-full h-full ${imageStyles}`} />
    </Link>
  );
}
